export const environment = {
  production: false,
  orgId: 'MFRM',
  siteTitle: 'Sleep.com Portal',
  assetsDirectory: 'mfrm',
  corporate: 'https://www.sleep.com',
  media: 'https://media.sleepscore.com/images/portal',
  api: 'https://qa-api-mfrm.sleepscore.com',
  url: 'https://qa.portal.sleep.com',
  id: 'mfrmWebPortal',
  privacyPolicyUrl: '',
  termsOfServiceUrl: '',
  dateFormat: 'MM-dd-yyyy',
  secret: 'We077DZKarkcqgMukCG7ETL3nxbOoe4LYAN444S2E00=',
  analytics: 'G-7LH6L44W4Y',
  buildNumber: '20240326.6.develop',
  appStores: {
    ios: {
      drive: '#',
      max: '#'
    },
    android: {
      drive: '#',
      max: '#'
    }
  },  
  login: {
    loginForm: false,
    providerLogins: true,
    providerLoginsStatic: false,
    providerLoginMenu: false
  }
};

if (window.location.hostname === 'localhost') {
  console.log('%cHello, Developer. Your localhost environment is QA.', 'color: orange; font-size: x-large;');
}

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
