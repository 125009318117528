import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { forkJoin, map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

export class MultiTranslationLoader implements TranslateLoader {

    constructor(
        private http: HttpClient
    ) { }

    deepMerge(target: any, ...sources: any): any {

        if (!sources.length) return target;
        const source = sources.shift();

        const isObject = (item: any) => {
            return (item && typeof item === 'object' && !Array.isArray(item));
        }

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, {
                        [key]: {}
                    });
                    this.deepMerge(target[key], source[key]);
                } else {
                    Object.assign(target, {
                        [key]: source[key]
                    });
                }
            }
        }

        return this.deepMerge(target, ...sources);

    }

    getTranslation(lang: string): Observable<any> {

        const assetsDirectory: string = environment.assetsDirectory;

        return forkJoin({
            core: this.http.get(`/assets/i18n/shared/${lang}.json`),
            partner: this.http.get(`/assets/i18n/${assetsDirectory}/${lang}.json`)
        }).pipe(
            map((res: any) => {
                const translations: any = this.deepMerge(res.core, res.partner);
                return translations;
            })
        );

    }

}
